<template lang="pug">
.training-card
  .bookmark._new(v-if="type === 'new'") Новая статья
  .bookmark._edit(v-if="type === 'edit'") Обновлено
  .article-info
    .card-read-time(v-if="time")
      ui-icon(:icon="UiIconNames.Icon_Clock" :size="12")
      span {{ time }}
    .card-title {{ title }}
  .card-sections(v-if="sections.length")
    ui-tag(v-for="s of sections" :key="s" :name="s")
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from 'vue';
import UiTag from "@/components/ui/tag/UiTag.vue";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "TrainingCard",
  components: {
    UiIcon,
    UiTag,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    sections: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    type: {
      type: String as PropType<'default'|'new'|'edit'>,
      default: 'default',
    },
  },
  setup() {
    return {
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/pages/training';

.training-card {
  display: flex;
  flex-flow: column;
  gap: 32px;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  background-color: white;

  padding: 20px;
  border: 1px solid #e4e7ed;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 12px rgba(0, 0, 0, .12);
  }

  .card-title {
    font-size: 16px;
    font-weight: 500;
  }

  .bookmark {
    position: absolute;
    top: -4px;
    right: -24px;

    font-size: 12px;
    line-height: 24px;
    font-weight: bold;
    display: flex;
    flex-flow: row;
    transform: rotate(20deg);
    width: 240px;
    justify-content: end;
    padding: 0 36px;
    box-sizing: border-box;

    &._edit {
      background-color: #fff6ec;
      color: #ee802c;
    }

    &._new {
      background-color: #e5f5f0;
      color: #529b2e;
    }
  }
}
</style>
